import React, {useState, useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import MaterialTable, { Column, Options } from '@material-table/core';
import { BiRefresh } from 'react-icons/bi';
import axios from 'axios';
import moment from 'moment';
import { Auth, Storage } from 'aws-amplify';
import { ValidationReportType } from '../../types/ValidationReportType';

const getRunIdTimes = (reports: ValidationReportType[]): any => {
    const hashMap: any = {};
    // populate hash map
    for (const report of reports) {
        const runId = report.reportMetadata.runId;
        if (!Array.isArray(hashMap[runId])) hashMap[runId] = [];
        hashMap[runId].push(report.lastModified);
    }
    // sort and pull out the last modified time
    for (const runId in hashMap) {
        const sorted = hashMap[runId].sort((a: number, b: number): number => b - a);
        hashMap[runId] = sorted[0];
    }
    return hashMap;
}

const ValidationReports: React.FC = () => {
    const tableRef: any = React.createRef();

    const [loading, setLoading] = useState<boolean>(false);
    const [validationReportsData, setValidationReportsData] = useState<ValidationReportType[] | null>(null);
    const [runIdLastModifiedData, setRunIdLastModifiedData] = useState<any>({});
    const [requestError, setRequestError] = useState<string | null>(null);
    const [downloadError, setDownloadError] = useState<string | null>(null);

    const getValidationReportsData = async() => {
        if (!loading){
            setRequestError(null);
            setLoading(true);
            try {
                const user = await Auth.currentAuthenticatedUser();
                const headers = {
                    'Authorization': user.getSignInUserSession().getIdToken().getJwtToken()
                };
                let config = {
                    headers,
                    params: {
                        date: moment().format("YYYY-MM-DD")
                    },
                };
                const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/listValidationReports`,
                    config);
                const validationReports: ValidationReportType[] = data.listValidationReportsResponseEntries;
                setLoading(false);
                setValidationReportsData(validationReports);
            } catch (err) {
                setLoading(false);
                setRequestError("Something went wrong fetching Validation reports, please try again");
            }
            
        }
    };

    const downloadFile = async (fileName: string, filePath: string) => {
        await Auth.currentAuthenticatedUser();
        Storage.configure({ level: 'public' });
        try {
            const response = await Storage.get(fileName, { download: true, customPrefix: { public: filePath } })
            if ((response.Body instanceof Blob)) {
                const data: Blob = response.Body;
                const blob = new Blob([data], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${fileName}`;
                link.click();
            } else {
                setDownloadError("Something went wrong downloading file, please try again");
            }
        } catch (err) {
            setDownloadError("Something went wrong downloading file, please try again");
        }
    }

    const fileNameRenderFn = (rowData: ValidationReportType) => 
        (<a href="#validation-alerts" className="link-primary" onClick={() => {
            if (rowData.s3Url) downloadFile(rowData.fileName, rowData.s3Path);
        }}>{rowData.fileName}</a>)

    const lastModifiedRenderFn = (rowData: ValidationReportType) => moment(rowData.lastModified).format('DD/MM/yyyy HH:mm:ss');

    const columns: Array<Column<ValidationReportType>> = [
        {
            title: "Filename",
            field: "fileName",
            grouping: false,
            render: fileNameRenderFn
        },
        {
            title: "Run ID",
            field: "reportMetadata.runId",
            width: 60,
        },
        {
            title: "Last Modified",
            field: "lastModified",
            grouping: false,
            render: lastModifiedRenderFn,
        },
    ];

    useEffect(() => {
        getValidationReportsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tableOptions: Options<ValidationReportType> = {
        idSynonym: 'fileName',
        search: false,
        showTitle: false,
        toolbar: false,
        selection: false,
        paging: false,
        grouping: false,
        showEmptyDataSourceMessage: true,
        actionsColumnIndex: columns.length,
        showDetailPanelIcon: false,
    };

    return (
        <div>
            <Row className="pb-2">
                <Col className="text-end">
                    <Button size="sm" variant="primary" role="refresh" onClick={getValidationReportsData}><BiRefresh size={20}/></Button>
                </Col>
            </Row>
            <Row className="pb-2">
                <Col>  
                    <MaterialTable
                        data-testid="table"
                        tableRef={tableRef}
                        isLoading={loading} 
                        options={tableOptions}
                        columns={columns} 
                        data={validationReportsData || []} />
                </Col>
            </Row>
            <Row>
                <Col className="text-end" id="validation-alerts">
                    <Alert data-testid="request-error-alert" className="text-center" show={!!requestError} variant="danger">{requestError}</Alert>
                    <Alert data-testid="download-error-alert" className="text-center" show={!!downloadError} variant="danger">{downloadError}</Alert>
                </Col>
            </Row>
        </div>
    );
};

export default ValidationReports;
