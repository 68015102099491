import React, { useState, useEffect } from "react"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Alert from 'react-bootstrap/Alert';
import { MdUploadFile, MdCheck, MdClose } from 'react-icons/md';
import MaterialTable, { Column, Options, Action, Localization } from '@material-table/core';
import axios from "axios";
import moment from 'moment';
import { Auth, Storage } from "aws-amplify";

type ReferenceDataType = {
    fileName: string;
    lastModified: number | null;
    s3Url: string| null;
    etag: string| null;
    refDataType: string;
    tableData?: {
        id?: any;
        showDetailPanel?: React.ReactNode;
    };
};

type RefDataResponseEntries = {
    sourceFilename: string;
    refDataType: string;
    accepted: boolean;
    acceptedS3Filename: string | null;
    csvParseErrors: {
        line: string[];
        lineNumber: number;
        exception: string,
    }[]
}[];

const ReferenceDataUpload: React.FC = () => {
    const tableRef: any = React.createRef();

    const [loading, setLoading] = useState<boolean>(false);
    const [referenceData, setReferenceData] = useState<ReferenceDataType[] | null>(null);
    const [requestError, setRequestError] = useState<string>("");
    const [successMessage, setSuccessMessage] = useState<React.ReactNode>("");
    const [uploadLoading, setUploadLoading] = useState<boolean>(false);
    const [uploadError, setUploadError] = useState<string>();
    const [selectedFile, setSelectedFile] = useState<File | null>();
    const [detailPanelOpen, setDetailPanelOpen] = useState<boolean>(false);
    
    const getReferenceData = async () => {
        if (!loading) {
            setLoading(true);
            const user = await Auth.currentAuthenticatedUser();
            const headers = {
                'Authorization': user.getSignInUserSession().getIdToken().getJwtToken()
            };
            try {
                const uri = `${process.env.REACT_APP_BASE_URL}/listReferenceData`;
                const response = await fetch(uri, {headers: headers});
                response.json()
                    .then(data => setReferenceData(data.refDataResponseEntries));
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setRequestError("Something went wrong fetching reference data, please try again");
            }
        }
    }

    const tempSuccessMessage = (rowData: ReferenceDataType, fileName: string | void) =>{
        let message = (<span>Reference file successfully uploaded</span>);
        if (fileName) {
            message = (<span>Reference file <code>{fileName}</code> successfully uploaded as <code>{rowData.fileName}</code></span>);
        }
        setSuccessMessage(message);
        setTimeout(()=>setSuccessMessage(""), 5000);
    };

    const uploadFile = async (file: ReferenceDataType) => {
        setUploadLoading(true);
        setUploadError("");
        const reader = new FileReader();
        const user = await Auth.currentAuthenticatedUser();
        const headers = {
            'Authorization': user.getSignInUserSession().getIdToken().getJwtToken()
        };
        reader.onload = async () => {
            const uri = `${process.env.REACT_APP_BASE_URL}/uploadReferenceData`
            try {
                const { data } = await axios.post(uri, {
                    "refDataRequestEntries": [
                        {
                            "sourceFilename": "",
                            "refDataType": file.refDataType,
                            "csvData": reader.result,
                            "ignoreCsvErrors": false
                        }
                    ]
                }, { headers });
                const refDataResponseEntries: RefDataResponseEntries = data.refDataResponseEntries
                if (refDataResponseEntries[0].csvParseErrors.length) {
                    setUploadError("Something went wrong, check data and try again");
                    setSelectedFile(null);
                } else {
                    tempSuccessMessage(file, selectedFile?.name);
                    getReferenceData();
                }
            } catch (err) {
                setUploadError(JSON.stringify(err));
            }
            setUploadLoading(false);
            setSelectedFile(null);
        }
        selectedFile && reader.readAsText(selectedFile);
    };

    const downloadFile = async (fileName: string) => {
        await Auth.currentAuthenticatedUser()
        Storage.configure({ level: 'public' });
        try {
            const response = await Storage.get(fileName, { download: true, cacheControl: 'no-cache', customPrefix: { public: "ReferenceData/accepted/" } })
            if ((response.Body instanceof Blob)) {
                const data: Blob = response.Body
                const blob = new Blob([data], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${fileName}`;
                link.click();
            } else {
                setUploadError("Something went wrong downloading file, please try again");
            }
        } catch (err) {
            setUploadError("Something went wrong downloading file, please try again");
        }
    };

    useEffect(() => {
        getReferenceData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fileNameRenderFn = (rowData: ReferenceDataType) => {
        if (rowData.s3Url) {
            return (<a href="#reference-alerts" className="link-primary" onClick={() => downloadFile(rowData.fileName)}>{rowData.fileName}</a>)
        }
        return rowData.fileName;
    }
            

    const lastModifiedRenderFn = (rowData: ReferenceDataType) => rowData.lastModified != null ? moment(rowData.lastModified).format('DD/MM/yyyy HH:mm:ss') : 'N/A';

    const columns: Array<Column<ReferenceDataType>> = [
        {
            title: "Filename",
            field: "fileName",
            type: 'string',
            render: fileNameRenderFn,
        },
        {
            title: "Last Modified",
            field: "lastModified",
            render: lastModifiedRenderFn,
        },
    ];

    const tableOptions: Options<ReferenceDataType> = {
        idSynonym: 'fileName',
        search: false,
        showTitle: false,
        toolbar: false,
        selection: false,
        paging: false,
        grouping: false,
        showEmptyDataSourceMessage: true,
        actionsColumnIndex: columns.length,
        showDetailPanelIcon: false,
        detailPanelType: 'single',
    };

    const showDetailPanel = (rowData: any) => {
        rowData.tableData = {
            showDetailPanel: tableRef.current.props.detailPanel
        };
        setDetailPanelOpen(true);
    };

    const hideDetailPanel = (rowData: any) => {
        const index = tableRef.current.dataManager.sortedData.findIndex((item: any) =>
            item.id === rowData.fileName || item.fileName === rowData.fileName);
        tableRef.current.dataManager.sortedData[index].tableData = {
            showDetailPanel: undefined
        };
        setDetailPanelOpen(false);
        setReferenceData(tableRef.current.dataManager.sortedData);
    };

    const detailPanel = ({ rowData }: { rowData: any }) => {
        return (
            <>
                <Stack direction="horizontal" gap={0} className="px-1">
                    <div className="fs-6 px-2 ms-auto">Are you sure you want to upload this file: <code>{selectedFile ? selectedFile.name : ''}</code>?</div>
                    <div className="d-flex">
                        <button className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit MuiIconButton-sizeMedium css-zylse7-MuiButtonBase-root-MuiIconButton-root" onClick={() => {
                            hideDetailPanel(rowData);
                        }} ><MdClose></MdClose></button>
                        <button data-testid="file-upload" className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit MuiIconButton-sizeMedium css-zylse7-MuiButtonBase-root-MuiIconButton-root" onClick={() => {
                            uploadFile(rowData);
                            hideDetailPanel(rowData);
                        }} ><MdCheck></MdCheck></button>
                    </div>
                    
                </Stack>
                    
            </>
        );
    };

    const changeHandler = (rowData: any) => (event: any) => {
        if (event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
            showDetailPanel(rowData);
        } else {
            hideDetailPanel(rowData);
        }
    };

    const actions: Action<ReferenceDataType>[] = [
        {
            icon: () => <MdUploadFile></MdUploadFile>,
            tooltip: "Upload file",
            onClick: (event: any, rowData: ReferenceDataType | ReferenceDataType[]) => {
                if (!Array.isArray(rowData)) {
                    document.getElementById(`hiddenInput_${rowData.fileName}`)?.click();
                }
                if (selectedFile) {
                    showDetailPanel(rowData);
                }
            },
            disabled: detailPanelOpen,
        }
    ]
    
    const localization: Localization = {
        header: {
            actions: 'Upload'
        }
    };
    return (
        <div>
            <Row className="py-2">
                <Col>
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={loading}
                        options={tableOptions}
                        localization={localization}
                        actions={actions}
                        detailPanel={detailPanel}
                        columns={columns}
                        data={referenceData || []} />
                    { (referenceData || []).map((rowData: any) => (
                        <input data-testid="file-input" key={rowData.fileName} id={`hiddenInput_${rowData.fileName}`} type="file" onChange={changeHandler(rowData)} style={{ display: 'none' }} />
                    ))}
                </Col>
            </Row>
            <Row>
                <Col id="reference-alerts">
                    <Alert data-testid="request-error-alert" className="text-center" show={!!requestError} variant="danger">{requestError}</Alert>
                    <Alert data-testid="upload-error-alert" className="text-center" show={!!uploadError} variant="danger">{uploadError}</Alert>
                    <Alert data-testid="upload-loading-alert" className="text-center" show={!!uploadLoading} variant="info">Uploading this file: <code>{selectedFile ? selectedFile.name : ''}</code></Alert>
                    <Alert data-testid="success-alert" className="text-center" show={!!successMessage} variant="success">{successMessage}</Alert>
                </Col>
            </Row>
        </div>
    )
}

export default ReferenceDataUpload
