import React, {useState, useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Stack from 'react-bootstrap/Stack';
import MaterialTable, { Column, Options, Action, Localization } from '@material-table/core';
import { BiRefresh } from 'react-icons/bi';
import { MdOutlineFileCopy, MdCheck, MdClose } from 'react-icons/md';
import axios from 'axios';
import moment from 'moment';
import { Auth, Storage } from 'aws-amplify';
import { OrderDataType } from '../../types/OrderDataType';

const getRunIdTimes = (orders: OrderDataType[]): any => {
    const hashMap: any = {};
    // populate hash map
    for (const order of orders) {
        const runId = order.orderMetadata.runId;
        if (!Array.isArray(hashMap[runId])) hashMap[runId] = [];
        hashMap[runId].push(order.lastModified);
    }
    // sort and pull out the last modified time
    for (const runId in hashMap) {
        const sorted = hashMap[runId].sort((a: number, b: number): number => b - a);
        hashMap[runId] = sorted[0];
    }
    return hashMap;
}

const CommitOrder: React.FC = () => {
    const tableRef: any = React.createRef();

    const [loading, setLoading] = useState<boolean>(false);
    const [commitPending, setCommitPending] = useState<boolean>(false);
    const [orderCommitted, setOrderCommitted] = useState<boolean>(false);
    const [orderData, setOrderData] = useState<OrderDataType[] | null>(null);
    const [runIdLastModifiedData, setRunIdLastModifiedData] = useState<any>({});
    const [requestError, setRequestError] = useState<string | null>(null);
    const [commitMessage, setCommitMessage] = useState<string | null>(null);
    const [downloadError, setDownloadError] = useState<string | null>(null);

    const commitSubmittable: boolean = !orderCommitted && !loading && !!orderData && !!orderData.length && !commitPending;

    const getOrderData = async() => {
        if (!loading){
            setRequestError(null);
            setLoading(true);
            try {
                const user = await Auth.currentAuthenticatedUser();
                const headers = {
                    'Authorization': user.getSignInUserSession().getIdToken().getJwtToken()
                };
                let config = {
                    headers,
                    params: {
                        orderDate: moment().format("YYYY-MM-DD")
                    },
                };
                const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/listOrders`,
                    config);
                const orders: OrderDataType[] = data.listOrdersResponseEntries;
                const runIdTimes: {[key: string]: number} = getRunIdTimes(orders);
                setRunIdLastModifiedData(runIdTimes);
                setLoading(false);
                setOrderData(orders);
                setOrderCommitted(!!orders.find((order: OrderDataType) => order.orderMetadata.committed));
            } catch (err) {
                setLoading(false);
                setRequestError("Something went wrong fetching orders, please try again");
            }
            
        }
    };

    const downloadFile = async (fileName: string, filePath: string) => {
        await Auth.currentAuthenticatedUser();
        Storage.configure({ level: 'public' });
        try {
            const response = await Storage.get(fileName, { download: true, customPrefix: { public: filePath } })
            if ((response.Body instanceof Blob)) {
                const data: Blob = response.Body;
                const blob = new Blob([data], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${fileName}`;
                link.click();
            } else {
                setDownloadError("Something went wrong downloading file, please try again");
            }
        } catch (err) {
            setDownloadError("Something went wrong downloading file, please try again");
        }
    }

    const commitOrder = async (rowData: OrderDataType) => {
        if (commitSubmittable) {
            setRequestError(null);
            setCommitPending(true);
            const user = await Auth.currentAuthenticatedUser();
            const headers = {
                'Authorization': user.getSignInUserSession().getIdToken().getJwtToken()
            };
            try {
                const s3Url = rowData?.s3Url;
                await axios.post(`${process.env.REACT_APP_BASE_URL}/commitOrder`, {
                    "requestEntries": [{s3Url}]
                }, { headers });
                getOrderData();
                setCommitMessage("Successfully committed");
                setTimeout(()=>setCommitMessage(""), 5000);
                setCommitPending(false);
            } catch (err) {
                setCommitPending(false);
                setRequestError("Something went wrong whilst committing, please try again");
        }
        }
    };

    const fileNameRenderFn = (rowData: OrderDataType) => 
        (<a href="#commit-alerts" className="link-primary" onClick={() => {
            if (rowData.s3Url) downloadFile(rowData.fileName, rowData.s3Path);
        }}>{rowData.fileName}</a>)

    const strategyRenderFn = (rowData: OrderDataType) => {
        const [, strategy] = rowData.orderMetadata.strategy.match(/^S([0-9])+$/) || [null, '1'];
        const strategyNumber = parseInt(strategy);
        const lightness = ((strategyNumber - 1) / 4 * 50) + 35;
        return (<span className="badge rounded-pill" style={{ backgroundColor: `hsl(240deg 100% ${lightness}%)` }}>{rowData.orderMetadata.strategy}</span>)
    };

    const lastModifiedRenderFn = (rowData: OrderDataType) => moment(rowData.lastModified).format('DD/MM/yyyy HH:mm:ss');

    const columns: Array<Column<OrderDataType>> = [
        {
            title: "Filename",
            field: "fileName",
            grouping: false,
            render: fileNameRenderFn
        },
        {
            title: "Run ID",
            field: "orderMetadata.runId",
            defaultGroupOrder: 1,
            defaultGroupSort: 'desc',
            width: 60,
            customSort: (a: any, b: any, type, sortDirection) => {
                if (type === 'group') {
                    return runIdLastModifiedData[a] === runIdLastModifiedData[b] ? 0 :
                        runIdLastModifiedData[a] > runIdLastModifiedData[b] ? 1 : -1;
                }
                if (sortDirection === 'asc') {
                    return 1;
                } else if (sortDirection === 'desc') {
                    return -1;
                }
                return 0;
            }, 
        },
        {
            title: "Strat",
            tooltip: "Strategy",
            defaultSort: "asc",
            field: "orderMetadata.strategy",
            width: 60,
            render: strategyRenderFn,
        },
        {
            title: "Last Modified",
            field: "lastModified",
            grouping: false,
            render: lastModifiedRenderFn,
        },
    ];

    useEffect(() => {
        getOrderData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tableOptions: Options<OrderDataType> = {
        idSynonym: 'fileName',
        search: false,
        showTitle: false,
        toolbar: false,
        selection: false,
        paging: false,
        grouping: true,
        persistentGroupingsId: 'Run ID',
        defaultExpanded: true,
        showEmptyDataSourceMessage: true,
        actionsColumnIndex: columns.length,
        showDetailPanelIcon: false,
        detailPanelType: 'single',
        rowStyle: (rowData) => ({
            backgroundColor:
                rowData.orderMetadata.committed ? "#c4dcff" : "",
        }),
    };

    const findDataIndex = (rowData: any) => {
        if (tableRef.current.dataManager.grouped) {
            let y = -1;
            const x = tableRef.current.dataManager.sortedData.findIndex((item: any) => {
                y = item.data.findIndex((item: any) => item.fileName === rowData.fileName);
                return y >= 0;
            });
            return [x, y];
        }
        const index = tableRef.current.dataManager.sortedData.findIndex((item: any) =>
            item.id === rowData.fileName || item.fileName === rowData.fileName);
        return [index];
    };

    const showDetailPanel = (rowData: any) => {
        const index = findDataIndex(rowData);
        tableRef.current.onToggleDetailPanel(index, tableRef.current.props.detailPanel);
    };

    const hideDetailPanel = (rowData: any) => {
        const index = findDataIndex(rowData);
        tableRef.current.onToggleDetailPanel(index, '');
    };

    const detailPanel = ({ rowData }: { rowData: any }) => {
        return (
            <>
                <Stack direction="horizontal" gap={0} className="px-1">
                    <div className="fs-6 px-2 ms-auto">Are you sure you want to commit this file?</div>
                    <div className="d-flex">
                        <button aria-label="Cancel commit file" className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit MuiIconButton-sizeMedium css-zylse7-MuiButtonBase-root-MuiIconButton-root" onClick={() => {
                            hideDetailPanel(rowData);
                        }} ><MdClose></MdClose></button>
                        <button aria-label="Confirm commit file" className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit MuiIconButton-sizeMedium css-zylse7-MuiButtonBase-root-MuiIconButton-root" onClick={() => {
                            commitOrder(rowData);
                            hideDetailPanel(rowData);
                        }} ><MdCheck></MdCheck></button>
                    </div>
                </Stack>

            </>
        );
    };

    const actions: Action<OrderDataType>[] = [
        {
            icon: () => <MdOutlineFileCopy></MdOutlineFileCopy>,
            tooltip: "Commit file",
            onClick: (event: any, rowData: OrderDataType | OrderDataType[]) => {
                showDetailPanel(rowData);
            },
            disabled: !commitSubmittable,
        }
    ]

    const localization: Localization = {
        header: {
            actions: 'Commit'
        }
    };

    return (
        <div>
            <Row className="pb-2">
                <Col className="text-end">
                    <Button size="sm" variant="primary" role="refresh" onClick={getOrderData}><BiRefresh size={20}/></Button>
                </Col>
            </Row>
            <Row className="pb-2">
                <Col>  
                    <MaterialTable
                        data-testid="table"
                        tableRef={tableRef}
                        isLoading={loading || commitPending} 
                        options={tableOptions}
                        localization={localization}
                        actions={actions}
                        detailPanel={detailPanel}
                        columns={columns} 
                        data={orderData || []} />
                </Col>
            </Row>
            <Row>
                <Col className="text-end" id="commit-alerts">
                    <Alert data-testid="request-error-alert" className="text-center" show={!!requestError} variant="danger">{requestError}</Alert>
                    <Alert data-testid="download-error-alert" className="text-center" show={!!downloadError} variant="danger">{downloadError}</Alert>
                    <Alert data-testid="success-alert" className="text-center" show={!!commitMessage} variant="success">{commitMessage}</Alert>
                    <Alert data-testid="already-commited-alert" className="text-center" show={orderCommitted} variant="warning">Order already committed today.</Alert>
                </Col>
            </Row>
        </div>
    );
};

export default CommitOrder;
