import React, {useEffect, useState} from 'react';
import './App.css';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from "./components/Header"
import NavigationTabs from "./components/NavigationTabs"
import FunctionCard from "./components/FunctionCard"
import RulesEngineConfiguration from "./components/RulesEngineConfiguration"
import ReferenceDataUpload from "./components/ReferenceDataUpload"
import CommitOrder from './components/CommitOrder';
import KPIFiles from './components/KPIFiles';
import { Amplify, Auth } from 'aws-amplify';
import { initAuthMock } from './mocks/auth';
import ValidationReports from './components/ValidationReports';

if (process.env.NODE_ENV === 'development' && window != null) {
  console.log('Mocking authentication');
  initAuthMock(Auth);
  console.log('Mocking server requests');
  require('./mocks/browser'); 
}

const StyledApp = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: #f3f3f3;
  display: flex;
  flex-direction: column;
  font-family: "Amazon Ember", "Helvetica", "sans-serif";
`
const NotSignedInText = styled.div`
  width: 100vw;
  cursor: pointer;
  text-align: center;
  margin: 20px;
`

const App: React.FC = () => {

  const [signedIn, setSignedIn] = useState<boolean>(false);

  useEffect(() => {
    configureAmplify();
    authenticateUser();
  }, []);

  const configureAmplify = () => {
    Amplify.configure({
      Auth: {
          region: process.env.REACT_APP_REGION,
          identityPoolRegion: process.env.REACT_APP_REGION,
          identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
          userPoolId: process.env.REACT_APP_USER_POOL_ID,
          userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
          mandatorySignIn: true,
          oauth: {
            domain: process.env.REACT_APP_OAUTH_DOMAIN,
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
            redirectSignOut: process.env.REACT_APP_REDIRECT_URL,
            responseType: 'code',
            client_id: process.env.REGION,
          },
        },
        Storage: {
        AWSS3: {
          bucket: process.env.REACT_APP_STORAGE_BUCKET,
          region: process.env.REACT_APP_REGION,
          identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        }
      }
    });
    Auth.configure();
  }

  const authenticateUser = async () =>{
        try{
          console.log("Authenticating user");
          const user = await Auth.currentAuthenticatedUser();
          console.log("User authenticated");
          setSignedIn(!!user);
        } catch (err) {
          console.log(err);
          console.log("User not authenticated, sending to sign in page");
          Auth.federatedSignIn({ customProvider: "COGNITO" });
        }
  }
    return (
    <StyledApp>
      <Header />
      <NavigationTabs/>
      { signedIn ? 
        <Container fluid className="p-2">
          <Row className="gx-2">
            <Col>
              <FunctionCard title='Upload Reference Data' subTitle='Select file to download or upload a new file' content={<ReferenceDataUpload />} />
              <FunctionCard title='KPI Files' subTitle='Select KPI file to download' content={<KPIFiles />} />
              <FunctionCard title='Validation Reports' subTitle='Select Validation report to download' content={<ValidationReports />} />
            </Col>
            <Col>
              <FunctionCard title='Trigger TCSO Engine Manually' subTitle='Click on run model to trigger the engine' content={<RulesEngineConfiguration />} />
              <FunctionCard title='Commit Order' subTitle='Select order file to commit' content={<CommitOrder />} />
            </Col>
          </Row>
        </Container>
        :
        <NotSignedInText onClick={()=> Auth.federatedSignIn({ customProvider: "COGNITO" })}>
          User Not found, if you are not re-directed automatically click <u>here</u> to sign in
        </NotSignedInText>
      }
    </StyledApp>
  );
}

export default App;
