import React from 'react';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';

const AppHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    color: white;
    text-align: center;
    background: linear-gradient(30deg, #00008B 55%, #ffffff);
    width: 100%;
    box-shadow: rgb(0 0 0 / 20%) 0 2px 1px -1px, rgb(0 0 0 / 14%) 0 1px 1px 0, rgb(0 0 0 / 12%) 0 1px 3px 0;
`;

const Logo = styled.img`
    height: 110px;
`

const HeaderHeading = styled.h1`
    font-size: 52px;
`
const TitleContent = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`

const signOut = async()=> {
    try {
        await Auth.signOut({ global: true });
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

const Header: React.FC = () => {
    return (
        <AppHeader>
            <TitleContent>
                <Logo src={require("../../assets/logo.png")} alt="Screwfix Logo"/>
                <HeaderHeading>Screwfix TCSO</HeaderHeading>
            </TitleContent>
            <Button aria-label="Sign out" className="mx-2" variant="primary" onClick={signOut}>
                Sign Out
            </Button>
        </AppHeader>
    )
};

export default Header;
