import React, {useState, useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import MaterialTable, { Column, Options } from '@material-table/core';
import { BiRefresh } from 'react-icons/bi';
import axios from 'axios';
import moment from 'moment';
import { Auth, Storage } from 'aws-amplify';
import { KPIFileType } from '../../types/KPIFileType';

const getRunIdTimes = (kpiFiles: KPIFileType[]): any => {
    const hashMap: any = {};
    // populate hash map
    for (const kpiFile of kpiFiles) {
        const runId = kpiFile.kpiMetadata.runId;
        if (!Array.isArray(hashMap[runId])) hashMap[runId] = [];
        hashMap[runId].push(kpiFile.lastModified);
    }
    // sort and pull out the last modified time
    for (const runId in hashMap) {
        const sorted = hashMap[runId].sort((a: number, b: number): number => b - a);
        hashMap[runId] = sorted[0];
    }
    return hashMap;
}

const KPIFiles: React.FC = () => {
    const tableRef: any = React.createRef();

    const [loading, setLoading] = useState<boolean>(false);
    const [kpiFilesData, setKPIFilesData] = useState<KPIFileType[] | null>(null);
    const [runIdLastModifiedData, setRunIdLastModifiedData] = useState<any>({});
    const [requestError, setRequestError] = useState<string | null>(null);
    const [downloadError, setDownloadError] = useState<string | null>(null);

    const getKPIFilesData = async() => {
        if (!loading){
            setRequestError(null);
            setLoading(true);
            try {
                const user = await Auth.currentAuthenticatedUser();
                const headers = {
                    'Authorization': user.getSignInUserSession().getIdToken().getJwtToken()
                };
                let config = {
                    headers,
                    params: {
                        date: moment().format("YYYY-MM-DD")
                    },
                };
                const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/listKPIFiles`,
                    config);
                const kpiFiles: KPIFileType[] = data.listKPIFilesResponseEntries;
                const runIdTimes: { [key: string]: number } = getRunIdTimes(kpiFiles);
                setRunIdLastModifiedData(runIdTimes);
                setLoading(false);
                setKPIFilesData(kpiFiles);
            } catch (err) {
                setLoading(false);
                setRequestError("Something went wrong fetching KPI files, please try again");
            }
            
        }
    };

    const downloadFile = async (fileName: string, filePath: string) => {
        await Auth.currentAuthenticatedUser();
        Storage.configure({ level: 'public' });
        try {
            const response = await Storage.get(fileName, { download: true, customPrefix: { public: filePath } })
            if ((response.Body instanceof Blob)) {
                const data: Blob = response.Body;
                const blob = new Blob([data], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${fileName}`;
                link.click();
            } else {
                setDownloadError("Something went wrong downloading file, please try again");
            }
        } catch (err) {
            setDownloadError("Something went wrong downloading file, please try again");
        }
    }

    const fileNameRenderFn = (rowData: KPIFileType) => 
        (<a href="#kpi-alerts" className="link-primary" onClick={() => {
            if (rowData.s3Url) downloadFile(rowData.fileName, rowData.s3Path);
        }}>{rowData.fileName}</a>)

    const strategyRenderFn = (rowData: KPIFileType) => {
        const [, strategy] = rowData.kpiMetadata.strategy.match(/^S([0-9])+$/) || [null, '1'];
        const strategyNumber = parseInt(strategy);
        const lightness = ((strategyNumber - 1) / 4 * 50) + 35;
        return (<span className="badge rounded-pill" style={{ backgroundColor: `hsl(240deg 100% ${lightness}%)` }}>{rowData.kpiMetadata.strategy}</span>)
    };

    const dcRenderFn = (rowData: KPIFileType) => {
        return (<Badge bg="secondary">{rowData.kpiMetadata.DC}</Badge>)
    };

    const lastModifiedRenderFn = (rowData: KPIFileType) => moment(rowData.lastModified).format('DD/MM/yyyy HH:mm:ss');

    const columns: Array<Column<KPIFileType>> = [
        {
            title: "Filename",
            field: "fileName",
            grouping: false,
            render: fileNameRenderFn
        },
        {
            title: "Run ID",
            field: "kpiMetadata.runId",
            defaultGroupOrder: 1,
            defaultGroupSort: 'desc',
            width: 60,
            customSort: (a: any, b: any, type, sortDirection) => {
                if (type === 'group') {
                    return runIdLastModifiedData[a] === runIdLastModifiedData[b] ? 0 :
                        runIdLastModifiedData[a] > runIdLastModifiedData[b] ? 1 : -1;
                }
                if (sortDirection === 'asc') {
                    return 1;
                } else if (sortDirection === 'desc') {
                    return -1;
                }
                return 0;
            }, 
        },
        {
            title: "Strat",
            tooltip: "Strategy",
            defaultSort: "asc",
            field: "kpiMetadata.strategy",
            width: 60,
            render: strategyRenderFn,
        },
        {
            title: "DC",
            tooltip: "Distribution Center",
            defaultSort: "asc",
            field: "kpiMetadata.DC",
            width: 60,
            render: dcRenderFn,
        },
        {
            title: "Last Modified",
            field: "lastModified",
            grouping: false,
            render: lastModifiedRenderFn,
        },
    ];

    useEffect(() => {
        getKPIFilesData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tableOptions: Options<KPIFileType> = {
        idSynonym: 'fileName',
        search: false,
        showTitle: false,
        toolbar: false,
        selection: false,
        paging: false,
        grouping: true,
        persistentGroupingsId: 'Run ID',
        defaultExpanded: true,
        showEmptyDataSourceMessage: true,
        actionsColumnIndex: columns.length,
        showDetailPanelIcon: false,
        detailPanelType: 'single',
    };

    return (
        <div>
            <Row className="pb-2">
                <Col className="text-end">
                    <Button size="sm" variant="primary" role="refresh" onClick={getKPIFilesData}><BiRefresh size={20}/></Button>
                </Col>
            </Row>
            <Row className="pb-2">
                <Col>  
                    <MaterialTable
                        data-testid="table"
                        tableRef={tableRef}
                        isLoading={loading} 
                        options={tableOptions}
                        columns={columns} 
                        data={kpiFilesData || []} />
                </Col>
            </Row>
            <Row>
                <Col className="text-end" id="kpi-alerts">
                    <Alert data-testid="request-error-alert" className="text-center" show={!!requestError} variant="danger">{requestError}</Alert>
                    <Alert data-testid="download-error-alert" className="text-center" show={!!downloadError} variant="danger">{downloadError}</Alert>
                </Col>
            </Row>
        </div>
    );
};

export default KPIFiles;
