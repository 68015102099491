import React from 'react';
import Card from 'react-bootstrap/Card';

interface FunctionCardProps {
    title: string;
    subTitle: string;
    content: React.ReactNode;
};

const FunctionCard: React.FC<FunctionCardProps> = (props) => {
    return (
        <Card className="mb-2">
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Subtitle>{props.subTitle}</Card.Subtitle>
                {props.content}
            </Card.Body>
        </Card>
    )
};

export default FunctionCard;
