import React, {useState} from "react"
import GridLoader from "react-spinners/GridLoader";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import axios from "axios";
import {Auth} from "aws-amplify";


const RulesEngineConfiguration: React.FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [requestError, setRequestError] = useState<string>("");
    const [successMessage, setSuccessMessage] = useState<string>("");

    const runModel = async() => {
        if(!loading){
            setLoading(true);
            setRequestError("");
            setSuccessMessage("")
            const user = await Auth.currentAuthenticatedUser()
            const headers = {
                'Authorization': user.getSignInUserSession().getIdToken().getJwtToken()
            }
            try {
                await axios.post(`${process.env.REACT_APP_BASE_URL}/triggerRunModel`,
                    {
                        "jobArguments": [
                            {
                                "key": "RUN_ID",
                                "value": Math.random().toString(36).slice(-4).toUpperCase()
                            }
                        ]
                    },
                    {headers: headers}
                )
                setSuccessMessage("Successfully Started Run")
                setTimeout(()=>setSuccessMessage(""), 5000);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                if (axios.isAxiosError(err)) {
                    if (err.response?.status === 429) {
                        setRequestError("Job is already running")
                    } else {
                        setRequestError(err.message)
                    }
                } else {
                    setRequestError(JSON.stringify(err))
                }
            }
        }
    }

    return (
        <div>
            <Row className="my-2">
                <Col>
                    <Button variant="primary" disabled={loading} onClick={runModel}>
                        {
                            loading ? <GridLoader color={"#ffffff"} loading={true} size={4} />: "Run Model"
                        }
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Alert data-testid="request-error-alert" variant="danger" show={!!requestError}>{requestError}</Alert>
                    <Alert data-testid="success-alert" variant="success" show={!!successMessage}>{successMessage}</Alert>
                </Col>
            </Row>
        </div>
    )
}

export default RulesEngineConfiguration
